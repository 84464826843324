const config = {
  name: "dillon",
  features: {
    accessCodes: false,
    moreInformation: true,
    hasParkingInstructions: true,
    hasPromoCodeWarning: false,
    userPhoneNumber: true
  },
  parkingSession: {
    // 24 hours, i.e 2 pm of prior day
    cancellationDeltaMs: 86400000,
    // Start time offset from midnight, i.e. 10 am
    startOffsetHrs: 14
  }
}
export default config
