const config = {
  name: "ohsu",
  features: {
    accessCodes: true,
    moreInformation: true,
    hasParkingInstructions: true,
    hasPromoCodeWarning: false,
    userPhoneNumber: true
  },
  parkingSession: {
    // that day, just before 8am
    cancellationDeltaMs: 0,
    // Start time offset from midnight, i.e. 8 am
    startOffsetHrs: 8
  }
}
export default config
