const config = {
  name: "manor-vail",
  features: {
    accessCodes: false,
    moreInformation: true,
    hasParkingInstructions: true,
    hasPromoCodeWarning: false,
    userPhoneNumber: true
  },
  parkingSession: {
    // 24 hours, i.e 7 am of prior day
    cancellationDeltaMs: 86400000,
    // Start time offset from midnight, i.e. 7 am
    startOffsetHrs: 7
  }
}
export default config
