const config = {
  name: "oakview",
  features: {
    accessCodes: true,
    moreInformation: true,
    hasParkingInstructions: true,
    hasPromoCodeWarning: true,
    userPhoneNumber: false
  },
  parkingSession: {
    // that day, 2 hours before each start time
    // e.g., if the parking session starts at 9am and 1pm, users can cancel before 7am and 11am respectively
    cancellationDeltaMs: 7200000,
    // Start time offset from midnight, i.e. 9 am and 1pm
    // This portal will have VALID BETWEEN rates that start at 9am and 1pm
    startOffsetHrs: 9
  }
}
export default config
